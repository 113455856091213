<template>
  <v-flex class="no-participant">
    <CirclePlaceholder :partnerInitials="initials" color="dark" />
    <div v-if="showText" class="cirlce-title">
      {{ $t("visits.video.noPartner") }}
    </div>
  </v-flex>
</template>

<script>
export default {
  name: "NoParticipant",
  props: {
    initials: String,
    participantId: String,
    showText: {
      default: true,
      type: Boolean,
    },
  },
  components: {
    CirclePlaceholder: () => import("./CirclePlaceholder.vue"),
  },
};
</script>

<style lang="scss" scoped>
.no-participant {
  /*height: 100vh;*/
  position: absolute;
  top: 40%;
  width: 100%;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  display: flex;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.24px;
  color: $white;
  opacity: 0.92;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.cirlce-title {
  top: 75%;
  width: 100%;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  display: flex;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.24px;
  color: $white;
  opacity: 0.92;
  @include mobile {
    font-size: 13px;
  }
}
</style>
